@import "~@codecademy/gamut-styles/utils";

$c-offset: 169;
$inner-offset: 287;
$outer-offset: 361;
$underline-offset: 77;
$animation-duration: 3s;

@keyframes draw-c {
  50% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-c-fast {
  10% {
    stroke-dashoffset: $c-offset;
  }
  50% {
    stroke-dashoffset: 0;
  }
  85% {
    stroke-dashoffset: $c-offset;
  }
}

@keyframes draw-inner {
  50% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-inner-fast {
  10% {
    stroke-dashoffset: $inner-offset;
  }
  50% {
    stroke-dashoffset: 0;
  }
  85% {
    stroke-dashoffset: $inner-offset;
  }
}

@keyframes draw-outer {
  50% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-outer-fast {
  10% {
    stroke-dashoffset: $outer-offset;
  }
  50% {
    stroke-dashoffset: 0;
  }
  85% {
    stroke-dashoffset: $outer-offset;
  }
}

@keyframes draw-underline {
  50% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-underline-fast {
  10% {
    stroke-dashoffset: $underline-offset;
  }
  50% {
    stroke-dashoffset: 0;
  }
  85% {
    stroke-dashoffset: $underline-offset;
  }
}

.fullPage {
  width: 100%;
  height: viewport-height(-($height-header));
}

.loading {
  width: rem(120px);
}

.underline {
  stroke-dasharray: $underline-offset;
  stroke-dashoffset: $underline-offset;
}
.c {
  stroke-dasharray: $c-offset;
  stroke-dashoffset: $c-offset;
}
.inner {
  stroke-dasharray: $inner-offset;
  stroke-dashoffset: $inner-offset;
}
.outer {
  stroke-dasharray: $outer-offset;
  stroke-dashoffset: $outer-offset;
}

.path {
  &.underline {
    animation: draw-underline $animation-duration infinite;
  }
  &.c {
    animation: draw-c $animation-duration infinite;
  }
  &.outer {
    animation: draw-outer $animation-duration infinite;
  }
  &.inner {
    animation: draw-inner $animation-duration infinite;
  }
}

.pathTwo {
  &.underline {
    animation: draw-underline-fast $animation-duration infinite;
  }
  &.c {
    animation: draw-c-fast $animation-duration infinite;
  }
  &.outer {
    animation: draw-outer-fast $animation-duration infinite;
  }
  &.inner {
    animation: draw-inner-fast $animation-duration infinite;
  }
}
